
import Vue from 'vue'
import PasswordInput from '~/components/inputs/PasswordInput.vue'
import EmailInput from '~/components/inputs/EmailInput.vue'
import ErrorToast from '~/components/toasts/ErrorToast.vue'
import { inputsValid } from '~/core/functions/inputsValid'
import { loginAction } from '~/core/functions/login'
import { generateHead } from '~/core/config/head.config'

export default Vue.extend({
  name: 'LoginPage',
  components: { EmailInput, PasswordInput, ErrorToast },
  layout: 'landing',
  data() {
    return {
      error: false,
      disabled: true,
      loginError: false,
      loading: true,
      buttonLoading: false,
    }
  },
  /* istanbul ignore next */
  head() {
    const head = generateHead('mhLanding')
    head.bodyAttrs.class = 'public-area-theme mh'
    return { ...head }
  },
  created() {
    // Using loading condition to avoid https://github.com/nuxt/nuxt.js/issues/1552
    this.$nextTick(() => {
      this.$data.loading = false
    })
  },
  beforeMount() {
    if (this.$auth.loggedIn) {
      // Forward to projects page if already logged in
      this.$router.push({ path: this.localePath(`/projects/`) })
    }
  },
  auth: false,
  methods: {
    validateInput() {
      const fields = ['email', 'password']
      const valid = inputsValid(this, fields, [], [])
      valid ? (this.$data.disabled = false) : (this.$data.disabled = true)
    },
    async callLoginAction() {
      this.$data.buttonLoading = true
      // @ts-ignore
      await loginAction(this, this.$refs.email.$data.value, this.$refs.password.$data.value)
      this.$data.buttonLoading = false
    },
  },
})
